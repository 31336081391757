import { Link } from "react-router-dom";
import { ReactComponent as MLIcon } from "../assets/model_leader_icon.svg";
import general from "../assets/generalleslyles.webp";
import aparnabhat from "../assets/aparnabhat.webp";
import jodysmith from "../assets/jodysmith.webp";
import sallyjewell from "../assets/sallyjewell.webp";
import tus from "../assets/tus.webp";
import meghan from "../assets/meghanwiete-scott.jpg";

function ModelLeadersPage() {
  return (
    <div className="bg-gray-100 w-screen min-h-screen flex justify-center items-center relative overflow-hidden">
      <div className="absolute bottom-0 right-0 w-96 h-96">
        <MLIcon className="absolute top-[-100%] right-[-100%] fill-[#1927533b] " />
      </div>
      <div className="px-6 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-28 lg:px-10 lg:py-24">
        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <h2 className="max-w-lg m-6 text-4xl leading-none tracking-tight text-[#003b8a] sm:text-4xl md:mx-auto font-roboto font-bold">
            <span className="relative inline-block">
              <svg
                viewBox="0 0 52 24"
                fill="currentColor"
                className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
              >
                <defs>
                  <pattern
                    id="1d4040f3-9f3e-4ac7-b117-7d4009658ced"
                    x="0"
                    y="0"
                    width=".135"
                    height=".30"
                  >
                    <circle cx="1" cy="1" r=".7" />
                  </pattern>
                </defs>
                <rect
                  fill="url(#1d4040f3-9f3e-4ac7-b117-7d4009658ced)"
                  width="52"
                  height="24"
                />
              </svg>
              <span className="relative ">Model</span>
            </span>{" "}
            Leaders
          </h2>
        </div>
        <div className="grid gap-10 sm:grid-cols-1 lg:grid-cols-4">
          <Link to={"https://open.substack.com/pub/modelleader/p/model-leader-of-the-month-aparna?utm_campaign=post&utm_medium=web&showWelcomeOnShare=true"}>
            <div className="relative overflow-hidden transition duration-300 transform rounded shadow-lg lg:hover:-translate-y-2 hover:shadow-2xl">
              <img
                className="object-cover w-full h-56 md:h-64 xl:h-80 object-top"
                src={aparnabhat}
                alt="Person"
              />
              <div className="absolute inset-0 flex flex-col justify-center px-5 py-4 text-center transition-opacity duration-300 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
                <p className="mb-1 text-lg font-bold text-gray-100">
                Aparna Bhat
                </p>
                <p className="mb-4 text-xs text-gray-100">
                January 2024
                </p>
                <p className="mb-4 text-xs tracking-wide text-gray-400"></p>
              </div>
            </div>
          </Link>
          <Link to={"https://modelleader.substack.com/p/model-leader-of-the-month-general?utm_campaign=post&utm_medium=web&triedRedirect=true"}>
            <div className="relative overflow-hidden transition duration-300 transform rounded shadow-lg lg:hover:-translate-y-2 hover:shadow-2xl">
              <img
                className="object-cover w-full h-56 md:h-64 xl:h-80 object-top"
                src={general}
                alt="Person"
              />
              <div className="absolute inset-0 flex flex-col justify-center px-5 py-4 text-center transition-opacity duration-300 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
                <p className="mb-1 text-lg font-bold text-gray-100">
                General Les Lyles
                </p>
                <p className="mb-4 text-xs text-gray-100">
                February 2024
                </p>
              </div>
            </div>
          </Link>
          <Link to={"https://open.substack.com/pub/modelleader/p/model-leader-of-the-month-jody-smith?utm_campaign=post&utm_medium=web&showWelcomeOnShare=true"}>
            <div className="relative overflow-hidden transition duration-300 transform rounded shadow-lg lg:hover:-translate-y-2 hover:shadow-2xl">
              <img
                className="object-cover w-full h-56 md:h-64 xl:h-80 object-top"
                src={jodysmith}
                alt="Person"
              />
              <div className="absolute inset-0 flex flex-col justify-center px-5 py-4 text-center transition-opacity duration-300 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
                <p className="mb-1 text-lg font-bold text-gray-100">
                  Jody Smith
                </p>
                <p className="mb-4 text-xs text-gray-100">
                March 2024
                </p> 
              </div>
            </div>
          </Link>
          <Link to={"https://www.youtube.com/watch?v=PWALsfRYg6E"}>
            <div className="relative overflow-hidden transition duration-300 transform rounded shadow-lg lg:hover:-translate-y-2 hover:shadow-2xl">
              <img
                className="object-cover w-full h-56 md:h-64 xl:h-80 object-top"
                src={sallyjewell}
                alt="Person"
              />
              <div className="absolute inset-0 flex flex-col justify-center px-5 py-4 text-center transition-opacity duration-300 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
                <p className="mb-1 text-lg font-bold text-gray-100">
                Sally Jewell
                </p>
                <p className="mb-4 text-xs text-gray-100">
                April 2024
                </p> 
              </div>
            </div>
          </Link>
          <div></div>
          <Link to={"https://open.substack.com/pub/modelleader/p/model-leader-of-the-month-the-unknown?utm_campaign=post&utm_medium=web&showWelcomeOnShare=true"}>
            <div className="relative overflow-hidden transition duration-300 transform rounded shadow-lg lg:hover:-translate-y-2 hover:shadow-2xl">
              <img
                className="object-cover w-full h-56 md:h-64 xl:h-80 object-top"
                src={tus}
                alt="Person"
              />
              <div className="absolute inset-0 flex flex-col justify-center px-5 py-4 text-center transition-opacity duration-300 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
                <p className="mb-1 text-lg font-bold text-gray-100">
                The Unknown Soldier
                </p>
                <p className="mb-4 text-xs text-gray-100">
                May 2024
                </p> 
              </div>
            </div>
          </Link>
          <Link to={"https://open.substack.com/pub/modelleader/p/model-leader-of-the-month-meghan?r=30iwet&utm_campaign=post&utm_medium=web&showWelcomeOnShare=true"}>
            <div className="relative overflow-hidden transition duration-300 transform rounded shadow-lg lg:hover:-translate-y-2 hover:shadow-2xl">
              <img
                className="object-cover w-full h-56 md:h-64 xl:h-80 object-top"
                src={meghan}
                alt="Person"
              />
              <div className="absolute inset-0 flex flex-col justify-center px-5 py-4 text-center transition-opacity duration-300 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
                <p className="mb-1 text-lg font-bold text-gray-100">
                Meghan Wieten-Scott
                </p>
                <p className="mb-4 text-xs text-gray-100">
                June 2024
                </p> 
              </div>
            </div>
          </Link>
          <div></div>
        </div>
      </div>
    </div>
  );
}

export default ModelLeadersPage;
