// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBPXFlReDe3zenp75BvGpggkv_f_rn9wwU",
  authDomain: "model-leader.firebaseapp.com",
  projectId: "model-leader",
  storageBucket: "model-leader.appspot.com",
  messagingSenderId: "519588120781",
  appId: "1:519588120781:web:77b1601666471a516a8184",
  measurementId: "G-QS54DWFJWR"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app)
const analytics = getAnalytics(app);