import ufLogo from "../assets/ufacilitate-logo.png";
import { ReactComponent as PPSLogo } from "../assets/pps-logo.svg";
import electivesLogo from "../assets/electives-logo.png";
import bodaLogo from "../assets/boda-logo.png";
import sctLogo from "../assets/sct-logo.png";

const EnterpriseSection = () => {
  return (
    <div className="md:mx-auto max-w-screen-xl p-6 relative overflow-hidden">
      <div className="relative overflow-hidden">
        <p className="text-base md:text-xl text-[#004AAD] my-2" >Where we partner with other organizations to develop mission-driven leaders: </p>
        <div className="grid grid-cols-2 gap-8 md:grid-cols-5 lg:grid-cols-5">
          <div className="col-span-1 flex items-center justify-center md:col-span-2 lg:col-span-1">
            <img className="h-22 cursor-pointer grayscale" src={ufLogo}  alt="ufacilitate-logo" onClick={() => (window.location.href = "https://designer-fb71112111ed.loginportal.site/preview/kf011d.e34me20flm6o2.d47179b9c4ea2bda6d6d0eedf1905e87/240919899980088151")}/>
          </div>
          <div className="col-span-1 flex items-center justify-center md:col-span-2 lg:col-span-1">
            <PPSLogo
              className="h-28 cursor-pointer grayscale"
              onClick={() => (window.location.href = "https://ourpublicservice.org/")}
            />
          </div>
          <div className="col-span-1 flex items-center justify-center md:col-span-2 lg:col-span-1">
            <img className="h-22 cursor-pointer grayscale" src={electivesLogo}  alt="electives-logo" onClick={() => (window.location.href = "https://www.electives.io/?hsLang=en")}/>
          </div>
          <div className="col-span-1 flex items-center justify-center md:col-span-2 lg:col-span-1">
            <img className="h-22 cursor-pointer grayscale" src={bodaLogo}  alt="boda-logo" onClick={() => (window.location.href = "https://bodagroup.com/")}/>
          </div>
          <div className="col-span-1 flex items-center justify-center md:col-span-2 lg:col-span-1">
            <img className="h-22 cursor-pointer grayscale" src={sctLogo}  alt="sct-logo" onClick={() => (window.location.href = "https://strategyconsultingteam.com/")}/>
          </div>
          {/* <div className="col-span-1 flex items-center justify-center md:col-span-2 lg:col-span-1">
            <MontgomeryLogo
              className="h-28 cursor-pointer"
              onClick={() =>
                (window.location.href = "https://www.montgomerycountymd.gov/")
              }
            />
          </div>
          <div className="col-span-1 flex items-center justify-center md:col-span-2 lg:col-span-1">
            <FiscalNoteLogo
              className="h-28 cursor-pointer"
              onClick={() => (window.location.href = "https://fiscalnote.com/")}
            />
          </div>
          <div className="col-span-1 flex items-center justify-center md:col-span-2 lg:col-span-1">
            <ByuLogo
              className="h-28 cursor-pointer"
              onClick={() => (window.location.href = "https://www.byu.edu/")}
            />
          </div>
          <div className="col-span-1 flex items-center justify-center md:col-span-2 lg:col-span-1">
            <BomfLogo
              className="h-28 cursor-pointer"
              onClick={() =>
                (window.location.href = "https://backonmyfeet.org/")
              }
            />
          </div>
          <div className="col-span-1 flex items-center justify-center md:col-span-2 lg:col-span-1">
            <NbptsLogo
              className="h-28 cursor-pointer"
              onClick={() => (window.location.href = "https://www.nbpts.org/")}
            />
          </div>
          <div className="col-span-1 flex items-center justify-center md:col-span-2 lg:col-span-1">
            <YnpncLogo
              className="h-28 cursor-pointer"
              onClick={() => (window.location.href = "https://ynpndc.org/")}
            />
          </div>
          <div className="col-span-1 flex items-center justify-center md:col-span-2 lg:col-span-1">
            <LincolnLogo
              className="h-28 cursor-pointer"
              onClick={() =>
                (window.location.href = "https://www.lincolncottage.org/")
              }
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default EnterpriseSection;
