import crw from "../assets/ClientRobertWilkie.jpeg";
import cpb from "../assets/ClientPeteButtigieg.jpeg";
import cxb from "../assets/ClientXavierBecerra.jpeg";
import cgr from "../assets/ClientGinaRaimondo.jpeg";
import cms from "../assets/ClientMichaelSmith.jpeg";
import csg from "../assets/ClientSuzyGeorge.png";

const GalleryClientSection = () => {
  return (
    <div className="md:mx-auto max-w-screen-xl p-12 relative overflow-hidden">
      <div className="relative overflow-hidden">
        <h1 className="text-left text-3xl md:text-4xl text-[#003b8a] font-roboto font-thin mb-4">
          Previous work with the Partnership for Public Service
        </h1>
        <p className="text-lg text-[#003b8a]">
          From 2010-2023 Andrew worked at{" "}
          <a className="underline" href="https://ourpublicservice.org/">
            the Partnership for Public Service
          </a>
          , where he supported dozens of federal agencies and thousands of
          federal leaders. During his tenure he designed and delivered multiple
          executive retreats for cabinet-level secretaries and their teams as
          well as other notable government executives.
        </p>
        {/* <div className="flex  w-full flex-wrap content-center justify-center p-5">
          <div className="grid grid-cols-2 md:grid-cols-6 gap-6">
            <div className="flex justify-center items-center flex-col w-full bg-white p-3">
              <img
                className=" object-cover"
                src={crw}
                alt="Department of Veteran Affairs Secretary"
              />
              <p className="text-[#003b8a] text-center">
                Department of Veteran Affairs Secretary
                <br />
                Robert Wilkie
              </p>
            </div>
            <div className="flex justify-center items-center flex-col w-full bg-white p-3">
              <img
                className=" w-full object-cover"
                src={cpb}
                alt="Department of Transportation Secretary"
              />
              <p className="text-[#003b8a] text-center">
                Department of Transportation Secretary
                <br />
                Pete Buttigieg
              </p>
            </div>
            <div className="flex justify-center items-center flex-col w-full bg-white p-3">
              <img
                className=" object-cover"
                src={cxb}
                alt="Department of Health and Human Services Secretary Xavier Becerra"
              />
              <p className="text-[#003b8a] text-center">
                Department of Health and Human Services Secretary Xavier Becerra
              </p>
            </div>
            <div className="flex justify-center items-center flex-col w-full bg-white p-3">
              <img
                className=" object-cover"
                src={cgr}
                alt="Department of Commerce Secretary"
              />
              <p className="text-[#003b8a] text-center">
                Department of Commerce Secretary
                <br />
                Gina Raimondo
              </p>
            </div>
            <div className="flex justify-center items-center flex-col w-full bg-white p-3">
              <img
                className=" object-cover"
                src={cms}
                alt="AmeriCorps CEO and President"
              />
              <p className="text-[#003b8a] text-center">
                AmeriCorps CEO and President
                <br />
                Michael Smith
              </p>
            </div>
            <div className="flex justify-center items-center flex-col w-full bg-white p-3">
              <img
                className=" object-cover"
                src={csg}
                alt="Department of State Chief of Staff"
              />
              <p className="text-[#003b8a] text-center">
                Department of State Chief of Staff
                <br />
                Suzy George
              </p>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default GalleryClientSection;
