import { useLayoutEffect } from "react";
import together from "../assets/together.jpg";
import both from "../assets/both.jpeg";
import BannerSection from "../components/BannerSection";
import banner from "../assets/aboutBanner.jpeg";
import { ReactComponent as BinocularIcon } from "../assets/binocularsIcon.svg";
import { ReactComponent as MapIcon } from "../assets/mapIcon.svg";
import { ReactComponent as CompassIcon } from "../assets/compassIcon.svg";
import { ReactComponent as MLIcon } from "../assets/model_leader_icon.svg";
import { useLocation } from "react-router-dom";

const AboutPage: React.FC = () => {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div className="bg-gray-100 flex flex-col w-screen relative overflow-hidden">
      <div className="absolute top-0 right-0 w-96 h-96">
        <MLIcon className="absolute bottom-[-150%] right-[-100%] fill-[#1927533b] " />
      </div>
      <BannerSection type="image" src={banner} size={"50vh"} />
      <div className="w-5/6 relative rounded-lg top-[-15vh] md:top-[-10vh] bg-white shadow-md p-4 mx-auto flex flex-col md:flex-row mb-[-10vh]">
        <div className="w-full">
          <div className="flex flex-row items-center justify-center w-full">
            <div className="flex flex-row m-4 w-full">
              <BinocularIcon className="basis-1/6 fill-[#003b8a] m-2" />
              <div className="flex flex-col">
                <h2 className="text-2xl  text-[#003b8a] font-roboto font-thin">
                  Vision
                </h2>
                <p className="text-lg text-[#004AAD]">
                  Lifting society through model leadership.
                </p>
              </div>
            </div>
            <div className="flex flex-row m-4 w-full">
              <MapIcon className="basis-1/6 fill-[#003b8a] m-2" />
              <div className="flex flex-col">
                <h2 className="text-2xl text-[#003b8a] font-roboto font-thin">
                  Mission
                </h2>
                <p className="text-lg text-[#004AAD]">
                  We develop model leaders who serve us all.
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-row m-4 justify-center">
            <CompassIcon className="basis-1/8 h-8 fill-[#003b8a] m-2" />
            <div className="flex flex-col basis-7/8">
              <h2 className="text-2xl text-[#003b8a] font-roboto font-thin">
                Values
              </h2>
              <ul className="text-lg text-[#004AAD]">
                <li>
                  <span className="font-bold">Competence</span>—We come with
                  deep expertise in the field of leadership development.
                </li>
                <li>
                  <span className="font-bold">Compassion</span>—We let love lead
                  in our design and delivery of leadership development efforts.{" "}
                </li>
                <li>
                  <span className="font-bold">Courage</span>—We act boldly as
                  coaches and facilitators, inviting public service leaders to
                  do the same.
                </li>
                <li>
                  <span className="font-bold">Curiosity</span>—We continually
                  learn and derive joy from our own development as leaders.{" "}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-screen-xl mx-auto px-4 py-12 sm:px-6 lg:px-8 relative">
        <div className="flex flex-col-reverse md:flex-row gap-8">
          <div className="w-full md:w-1/2">
            <img
              src={both}
              alt="About Hero"
              className="rounded-md shadow-md h-[50vh] object-cover"
            />
          </div>
          <div className="w-full md:w-1/2">
            <div className="flex flex-col space-y-4">
              <h1 className="text-4xl text-[#003b8a] font-roboto font-thin rounded-lg p-4">
                Our Story
              </h1>
              <p className="text-lg text-[#003b8a]">
              We strive to live our lives in joyful service. We believe in more perfect unions–in our homes, in our organizations and in our society. 
              </p>
              <p className="text-lg text-[#003b8a]">
              In the spirit of Kennedy’s call to “ask not what your country can do for you, but what you can do for your country” we strive to lift and unify wherever we go. Whether serving our five children, supporting youth groups in their development, leading as executives, publishing, speaking, facilitating, or coaching–every day we work to help leaders learn self-awareness, lead people and lift society.
              </p>
              <p className="text-lg text-[#003b8a] my-4">
              This service orientation is core to our love story (we met while serving!) and has motivated us to learn languages, to move to new places, to raise five children, to attach ourselves to causes bigger than us, to found Model Leader and ultimately to contribute as best we can to a more unified society.
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col-reverse md:flex-row gap-8 mt-2">
          <div className="w-full md:w-1/2 flex flex-col space-y-4">
            <p className="text-lg text-[#003b8a]">
            We believe that there is no better way to move toward this aspiration than through the power of public service leadership. Yet to harness this possibility, public service leaders need support and development.
            </p>
            <p className="text-lg text-[#003b8a]">
            Mission-driven leaders who work with us will leave surprised by the possible within themselves and the possibility of what they can do. An abundant world awaits!
            </p>
          </div>
          <div className="w-full md:w-1/2">
            <img
              src={together}
              alt="About Hero"
              className="rounded-md shadow-md h-[50vh] object-cover w-full object-top"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
