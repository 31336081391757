import { ReactComponent as USDA } from "../assets/gov/usda-symbol.svg";
import dfcLogo from "../assets/dfc-logo.png";
import { ReactComponent as SBM } from "../assets/sbm-logo.svg";
import aclsLogo from "../assets/acls-fullname-logo.png";
import efifLogo from "../assets/efif-logo.png";
import accionLogo from "../assets/accion-logo.png";
import aclLogo from "../assets/acl-logo.png";
import pecfLogo from "../assets/pecf-logo.png";
import iavmLogo from "../assets/iavm-logo.jpeg";

const GovernmentSection = () => {
  return (
    <div className="md:mx-auto max-w-screen-xl p-6 relative overflow-hidden">
      <div className="relative overflow-hidden ">
        <h1 className="text-left text-3xl md:text-4xl text-[#003b8a] font-roboto font-thin mb-4">
          Model Leader Clients
        </h1>
        <p className="text-base md:text-xl text-[#004AAD] my-2" >Where we’re developing executive leaders who lift society </p>
        <div className="grid grid-cols-2 gap-8 md:grid-cols-9 lg:grid-cols-9">
          <div className="col-span-1 flex items-center justify-center md:col-span-2 lg:col-span-1">
            <USDA
              className="h-28 cursor-pointer"
              onClick={() => (window.location.href = "https://www.usda.gov/")}
            />
          </div>
          <div className="col-span-1 flex items-center justify-center md:col-span-2 lg:col-span-1">
            <img className="h-22 cursor-pointer grayscale" src={dfcLogo}  alt="dfc-logo" onClick={() => (window.location.href = "https://www.dfc.gov/")}/>
          </div>
          <div className="col-span-1 flex items-center justify-center md:col-span-2 lg:col-span-1">
            <img className="h-22 cursor-pointer grayscale" src={aclsLogo}  alt="acls-logo" onClick={() => (window.location.href = "https://www.acls.org/")}/>
          </div>
          <div className="col-span-1 flex items-center justify-center md:col-span-2 lg:col-span-1">
            <img className="h-18 cursor-pointer grayscale" src={efifLogo}  alt="efif-logo" onClick={() => (window.location.href = "https://efifoundation.org/")}/>
          </div>
          <div className="col-span-1 flex items-center justify-center md:col-span-2 lg:col-span-1">
            <img className="h-18 cursor-pointer grayscale" src={accionLogo}  alt="accion-logo" onClick={() => (window.location.href = "https://www.accion.org/")}/>
          </div>
          <div className="col-span-1 flex items-center justify-center md:col-span-2 lg:col-span-1">
            <img className="h-18 cursor-pointer grayscale" src={aclLogo}  alt="acl-logo" onClick={() => (window.location.href = "https://acl.gov/")}/>
          </div>
          <div className="col-span-1 flex items-center justify-center md:col-span-2 lg:col-span-1">
            <img className="h-18 cursor-pointer grayscale" src={pecfLogo}  alt="pecf-logo" onClick={() => (window.location.href = "https://pecf.org/")}/>
          </div>
          <div className="col-span-1 flex items-center justify-center md:col-span-2 lg:col-span-1">
            <img className="h-18 cursor-pointer grayscale" src={iavmLogo}  alt="iavm-logo" onClick={() => (window.location.href = "https://iavm.org/")}/>
          </div>
          <div className="col-span-1 flex items-center justify-center md:col-span-2 lg:col-span-1">
            <SBM className="h-18 cursor-pointer grayscale" onClick={() => (window.location.href = "https://smallbusinessmajority.org/")}/>
          </div>
          {/* <div className="col-span-1 flex items-center justify-center md:col-span-2 lg:col-span-1">
            <DMVA
              className="h-28 cursor-pointer"
              onClick={() => (window.location.href = "https://www.va.gov/")}
            />
          </div>
          <div className="col-span-1 flex items-center justify-center md:col-span-2 lg:col-span-1">
            <DOT
              className="h-28 cursor-pointer"
              onClick={() =>
                (window.location.href = "https://www.transportation.gov/")
              }
            />
          </div>
          <div className="col-span-1 flex items-center justify-center md:col-span-2 lg:col-span-1">
            <IRS
              className="h-28 cursor-pointer"
              onClick={() => (window.location.href = "https://www.irs.gov/")}
            />
          </div>
          <div className="col-span-1 flex items-center justify-center md:col-span-2 lg:col-span-1">
            <HHS
              className="h-28 cursor-pointer"
              onClick={() => (window.location.href = "https://www.hhs.gov/")}
            />
          </div>
          <div className="col-span-1 flex items-center justify-center md:col-span-2 lg:col-span-1">
            <DOC
              className="h-28 cursor-pointer"
              onClick={() =>
                (window.location.href = "https://www.commerce.gov/")
              }
            />
          </div>
          <div className="col-span-1 flex items-center justify-center md:col-span-2 lg:col-span-1">
            <Americorp
              className="h-28 cursor-pointer"
              onClick={() => (window.location.href = "https://americorps.gov/")}
            />
          </div>
          <div className="col-span-1 flex items-center justify-center md:col-span-2 lg:col-span-1">
            <DOS
              className="h-28 cursor-pointer"
              onClick={() => (window.location.href = "https://www.state.gov/")}
            />
          </div>
          <div className="col-span-1 flex items-center justify-center md:col-span-2 lg:col-span-1">
            <DOL
              className="h-28 cursor-pointer"
              onClick={() => (window.location.href = "https://www.dol.gov/")}
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default GovernmentSection;
